import Vue from "vue";
import VueRouter from "vue-router";

import BaseLayout from "@/layout/BaseLayout";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: BaseLayout,
    name: "BaseLayout",
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "Home" */ "@/views/Home.vue"),
        meta: { title: "主页", showTabBar: true },
      },
    ],
  },
  {
    path: "/platform",
    name: "platform",
    component: () => import("@/views/components/platform/platformserve.vue"),
  },
  {
    path: "/platformdy",
    name: "platformdy",
    component: () =>
      import(
        /* webpackChunkName: "Home" */ "@/views/components/platform/platformdy.vue"
      ),
  },
  {
    path: "/platformks",
    name: "platformks",
    component: () =>
      import(
        /* webpackChunkName: "Home" */ "@/views/components/platform/platformks.vue"
      ),
  },
  {
    path: "/platformwx",
    name: "platformwx",
    component: () =>
      import(
        /* webpackChunkName: "Home" */ "@/views/components/platform/platformwx.vue"
      ),
  },
  {
    path: "/platformbkserve",
    name: "platformbkserve",
    component: () =>
      import(
        /* webpackChunkName: "Home" */ "@/views/components/platform/platformbkserve.vue"
      ),
  },
  {
    path: "/platformkgstore",
    name: "platformkgstore",
    component: () =>
      import(
        /* webpackChunkName: "Home" */ "@/views/components/platform/platformkgstore.vue"
      ),
  },
  {
    path: "/platformhelp",
    name: "platformhelp",
    component: () =>
      import(
        /* webpackChunkName: "Home" */ "@/views/components/platform/platformhelp.vue"
      ),
  },
  {
    path: "/personcase",
    name: "personcase",
    component: () => import("@/views/components/wellperson/personcase.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  // base: process.env.BASE_URL,
  // base:'/dist',
  // base:'/home/mobile',
  routes,
});

export default router;
